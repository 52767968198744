export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_RCW'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_RCW'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_RCW'
export const SET_CHOICES = 'SET_CHOICES_RCW'
export const CHANGE_KEY_TEXT = 'CHANGE_KEY_TEXT_RCW'
export const SET_MODE = 'SET_MODE_RCW'
export const LOAD_DONOR_BOX = 'LOAD_DONOR_BOX_RCW'
export const SET_PAST_IP = 'SET_PAST_IP_RCW'
export const SET_HISTORY = 'SET_HISTORY_RCW'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RCW'
export const INSTANT_INIT = 'INSTANT_INIT_RCW'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RCW'
export const REPLACE_STATE = 'REPLACE_STATE_RCW'
export const SET_SHOW_MODE = 'SET_SHOW_MODE_RCW'
export const SET_INPUT_SELECT_MODE = 'SET_INPUT_SELECT_MODE_RCW'
export const SET_INPUT_SELECTED = 'SET_INPUT_SELECTED_RCW'
export const SET_COLOR_SCHEME_SELECTED = 'SET_COLOR_SCHEME_SELECTED_RCW'
export const SET_INPUT_METHOD = 'SET_INPUT_METHOD_RCW'
export const UPDATE_COLOR_WHEEL_STATE = 'UPDATE_COLOR_WHEEL_STATE_RCW'
export const UPDATE_ALL_COLOR_WHEEL_STATES = 'UPDATE_ALL_COLOR_WHEEL_STATES_RCW'
export const UPDATE_PALETTE_WHEEL_STATE = 'UPDATE_PALETTE_WHEEL_STATE_RCW'
