import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_MODE,
  CHANGE_KEY_TEXT,
  SET_HISTORY,
  SET_TITLE_OBJ,
  INSTANT_INIT,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
  SET_SHOW_MODE,
  SET_INPUT_SELECT_MODE,
  SET_INPUT_SELECTED,
  SET_COLOR_SCHEME_SELECTED,
  SET_INPUT_METHOD,
  UPDATE_COLOR_WHEEL_STATE,
  UPDATE_ALL_COLOR_WHEEL_STATES,
  UPDATE_PALETTE_WHEEL_STATE,
} from './constants'
import { createReducer } from '../reducerUtil'
import cuid from 'cuid'

const initialState = {
  resultSelected: null,
  resultIndex: -1,
  choices: [],
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  history: [],
  turnTable: null,
  turnTableSize: 300,
  inputKeyText: cuid(),
  mode: 0,
  showMode: ['Color', 'Text', 'HEX', 'RGB'],
  inputSelectMode: false,
  inputSelected: [],
  colorSchemeSelected: {
    label: 'PW Colors',
    colors: [
      { name: 'Crimson', hex: '#e91c3c' },
      { name: 'Orange', hex: '#f67d1d' },
      { name: 'Buttercup', hex: '#f6b71d' },
      { name: 'Green', hex: '#2b580c' },
      { name: 'Mountain Meadow', hex: '#129692' },
      { name: 'Blue', hex: '#3b4ebe' },
      { name: 'Magenta', hex: '#cb2a99' },
      { name: 'Purple Heart', hex: '#8b25ba' },
      { name: 'Raven Black', hex: '#3d3d3d' },
      { name: 'White', hex: '#ffffff' },
      { name: 'Silver Chalice', hex: '#808080' },
      { name: 'Antique Brass', hex: '#633f1b' },
    ],
  },
  inputMethod: 'ColorWheel', // ColorWheel, ManualInput, ImagePicker
  colorWheel: {
    combination: 'Complementary',
    pointer: 'Random',
    pointerPosition: 0.5, // the position spinning pointer on the color wheel, where 0 is the center and 1 is the outer edge.
  },
  paletteWheel: {
    brightness: 100, // Represents the V (Value) in HSV
    palette: [],
  },
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
  }
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
    resultIndex: payload.resultIndex,
  }
}

export const setChoices = (state, payload) => {
  return {
    ...state,
    choices: payload.choices,
    turnTable: null,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const setMode = (state, payload) => {
  return {
    ...state,
    mode: payload.mode,
  }
}

export const changeKeyText = (state, payload) => {
  return {
    ...state,
    inputKeyText: cuid(),
  }
}

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const resetInitialState = (state, payload) => {
  const { turnTableSize, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  return {
    ...state,
    ...payload.data,
    inputKeyText: cuid(),
  }
}

export const setShowMode = (state, payload) => {
  return {
    ...state,
    showMode: payload.showMode,
    turnTable: null,
  }
}

export const setInputSelectMode = (state, payload) => {
  return {
    ...state,
    inputSelectMode: payload.status,
  }
}

export const setInputSelected = (state, payload) => {
  return {
    ...state,
    inputSelected: payload.inputSelected,
  }
}

export const setColorSchemeSelected = (state, payload) => {
  return {
    ...state,
    colorSchemeSelected: payload.colorSchemeSelected,
  }
}

export const setInputMethod = (state, payload) => {
  return {
    ...state,
    inputMethod: payload.method,
  }
}

export const updateColorWheelState = (state, payload) => {
  return {
    ...state,
    colorWheel: {
      ...state.colorWheel,
      [payload.text]: payload.value,
    },
  }
}

export const updateAllColorWheelStates = (state, payload) => {
  return {
    ...state,
    colorWheel: payload,
  }
}

export const updatePaletteWheelState = (state, payload) => {
  return {
    ...state,
    paletteWheel: {
      ...state.paletteWheel,
      [payload.text]: payload.value,
    },
    turnTable:
      payload.text === 'brightness' &&
      state.paletteWheel.brightness !== payload.value
        ? null
        : state.turnTable,
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_CHOICES]: setChoices,
  [SET_MODE]: setMode,
  [CHANGE_KEY_TEXT]: changeKeyText,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  [SET_HISTORY]: setHistory,
  [SET_TITLE_OBJ]: setTitleObj,
  [INSTANT_INIT]: instantInit,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
  [SET_SHOW_MODE]: setShowMode,
  [SET_INPUT_SELECT_MODE]: setInputSelectMode,
  [SET_INPUT_SELECTED]: setInputSelected,
  [SET_COLOR_SCHEME_SELECTED]: setColorSchemeSelected,
  [SET_INPUT_METHOD]: setInputMethod,
  [UPDATE_COLOR_WHEEL_STATE]: updateColorWheelState,
  [UPDATE_ALL_COLOR_WHEEL_STATES]: updateAllColorWheelStates,
  [UPDATE_PALETTE_WHEEL_STATE]: updatePaletteWheelState,
})
