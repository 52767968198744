import { combineReducers } from 'redux'
import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import mainReducer from './homepage/reducer'
// import locationReducer from './location/reducer'
// import asyncReducer from './async/reducer'
import modalReducer from './modal/reducer'
import rtgReducer from './rtg/reducer'
// import globalReducer from './global/reducer'
import ynwReducer from './ynw/reducer'
import rngReducer from './rng/reducer'
import turntableReducer from './turntable/reducer'
import toolSettingsReducer from './toolSettings/reducer'
import rlgReducer from './rlg/reducer'
import rigReducer from './rig/reducer'
import shareReducer from './shareWheel/reducer'
import authReducer from './auth/reducer'
import commonReducer from './common/reducer'
import rcgReducer from './rcg/reducer'
import rdgReducer from './rdg/reducer'
import icpReducer from './icp/reducer'
import rcwReducer from './rcw/reducer'
import nflReducer from './nfl/reducer'
import nbaReducer from './nba/reducer'
// import accountReducer from './account/reducer'
// import { refactorSoundSettings, refactorBgColorSettings } from '../utils'
import {
  mainMigration,
  rtgMigration,
  rngMigration,
  generalMigration,
  settingsMigration,
  rcwMigration,
} from '../utils/persistMigration'

// const InitializeInputsImagePW = createTransform(
//   null,
//   (outboundState, key) => {
//     return resetInputsImage(outboundState)
//   },
//   // define which reducers this transform gets called for.
//   { whitelist: ['choices'] },
// )

const persistConfig = {
  key: 'primary',
  storage,
  version: 0,
  whitelist: [
    'choices',
    'turnTableSize',
    'mode',
    // 'currentChoicesKey',
    'savedChoicesList',
    // 'listName',
    'title',
    'weightOn',
  ],
  migrate: createMigrate(mainMigration, { debug: false }),
  // transforms: [InitializeInputsImagePW],
}

const persistConfigRTG = {
  key: 'rtg',
  storage,
  version: 0,
  whitelist: [
    'people',
    // 'currentPeopleKey',
    'savedPeopleList',
    'title',
    'numGroups',
    'evenSplit',
    'groupNames',
    'showIcon',
    'pickRepresentative',
  ],
  migrate: createMigrate(rtgMigration, { debug: false }),
}

const persistConfigYNW = {
  key: 'ynw',
  storage,
  version: 0,
  whitelist: ['turnTableSize', 'inputMode', 'numInputSet', 'title'],
  migrate: createMigrate(generalMigration, { debug: false }),
}

const persistConfigRNG = {
  key: 'rng',
  storage,
  version: 1,
  whitelist: [
    'turnTableSize',
    'mode',
    'title',
    'minValue',
    'maxValue',
    'intervalValue',
    'typeOption',
    'autoSpinMerge',
    'excludeFieldValue',
    'formulaFieldValue',
    'inputMethod',
    'digitNumber',
    'digitRanges',
  ],
  migrate: createMigrate(rngMigration, { debug: false }),
}

const persistConfigRLG = {
  key: 'rlg',
  storage,
  version: 0,
  whitelist: [
    'turnTableSize',
    'mode',
    'title',
    'inputMode',
    'styleMode',
    'customInput',
  ],
  migrate: createMigrate(generalMigration, { debug: false }),
}

const persistConfigRCG = {
  key: 'rcg',
  storage,
  version: 0,
  whitelist: [
    'choices',
    'turnTableSize',
    'mode',
    'title',
    'continentsActive',
    'showMode',
  ],
  migrate: createMigrate(generalMigration, { debug: false }),
}

const persistConfigRIG = {
  key: 'rig',
  storage,
  version: 0,
  whitelist: [
    'choices',
    'turnTableSize',
    'mode',
    // 'currentChoicesKey',
    'savedChoicesList',
    // 'totalImages',
    'title',
  ],
  migrate: createMigrate(generalMigration, { debug: false }),
}

const persistConfigRDG = {
  key: 'rdg',
  storage,
  version: 0,
  whitelist: [
    'choices',
    'turnTableSize',
    'mode',
    'title',
    'inputMode',
    'inputs',
    'daysMode',
  ],
  migrate: createMigrate(generalMigration, { debug: false }),
}

// const InitializeMusicSettings = createTransform(
//   null,
//   (outboundState, key) => {
//     return refactorSoundSettings(outboundState)
//   },
//   // define which reducers this transform gets called for.
//   { whitelist: ['music'] },
// )

// const InitializeBgColorSettings = createTransform(
//   null,
//   (outboundState, key) => {
//     return refactorBgColorSettings(outboundState)
//   },
//   // define which reducers this transform gets called for.
//   { whitelist: ['bgColor'] },
// )

const persistConfigToolSettings = {
  key: 'settings',
  storage,
  version: 2,
  whitelist: [
    'wheelColors',
    'bgColor',
    'spinBehavior',
    'music',
    'confetti',
    'bgImage',
    'spinButton',
    'banners',
    'rtgSpinBehavior',
  ],
  // stateReconciler: autoMergeLevel2,
  // transforms: [InitializeMusicSettings, InitializeBgColorSettings],
  migrate: createMigrate(settingsMigration, { debug: true }),
}

const persistConfigShare = {
  key: 'share',
  storage,
  whitelist: [
    // 'lastSharePage',
    'createdCount',
    'lastCreate',
    'dataFunctions',
    'lastSharePath',
  ],
}

const persistConfigAuth = {
  key: 'auth',
  storage,
  whitelist: ['lastActiveDate', 'premiumHistory'],
}

const persistConfigICP = {
  key: 'icp',
  storage,
  whitelist: [
    'inputs',
    'choices',
    'turnTableSize',
    'title',
    'username',
    'postPhoto',
    'resultReverse',
    'numWinners',
    'accessToken',
    'mode',
    // 'autoSpin',
  ],
}

const persistConfigRCW = {
  key: 'rcw',
  storage,
  version: 1,
  whitelist: [
    'choices',
    'turnTableSize',
    'mode',
    'title',
    'showMode',
    'inputMethod',
    'colorWheel',
  ],
  migrate: createMigrate(rcwMigration, { debug: false }),
}

const persistConfigNFL = {
  key: 'nfl',
  storage,
  whitelist: [
    'choices',
    'turnTableSize',
    'mode',
    'title',
    'conferenceActive',
    'showMode',
    'teamsActive',
  ],
}

const persistConfigNBA = {
  key: 'nba',
  storage,
  whitelist: [
    'choices',
    'turnTableSize',
    'mode',
    'title',
    'conferenceActive',
    'showMode',
    'teamsActive',
  ],
}

const rootReducer = combineReducers({
  main: persistReducer(persistConfig, mainReducer),
  // location: locationReducer,
  // async: asyncReducer,
  modal: modalReducer,
  rtg: persistReducer(persistConfigRTG, rtgReducer),
  // common: commonReducer,
  ynw: persistReducer(persistConfigYNW, ynwReducer),
  rng: persistReducer(persistConfigRNG, rngReducer),
  rlg: persistReducer(persistConfigRLG, rlgReducer),
  rig: persistReducer(persistConfigRIG, rigReducer),
  rcg: persistReducer(persistConfigRCG, rcgReducer),
  rdg: persistReducer(persistConfigRDG, rdgReducer),
  icp: persistReducer(persistConfigICP, icpReducer),
  rcw: persistReducer(persistConfigRCW, rcwReducer),
  nfl: persistReducer(persistConfigNFL, nflReducer),
  nba: persistReducer(persistConfigNBA, nbaReducer),
  turntable: turntableReducer,
  settings: persistReducer(persistConfigToolSettings, toolSettingsReducer),
  share: persistReducer(persistConfigShare, shareReducer),
  auth: persistReducer(persistConfigAuth, authReducer),
  common: commonReducer,
  // account: accountReducer,
  // global: persistReducer(persistConfigGlobal, globalReducer),
})

export default rootReducer
