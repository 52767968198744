import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_HISTORY,
  SET_INPUTS,
  SET_TITLE_OBJ,
  SET_USERNAME,
  SET_POST_PHOTO,
  SET_RESULT_REVERSE,
  CHANGE_KEY_TEXT,
  SET_NUM_WINNERS,
  SET_ACCESS_TOKEN,
  // SET_AUTO_SPIN,
  SET_POST_CODE,
  SET_MODE,
  OPEN_WINNER_MODAL,
} from './constants'
import { setStartRotate } from '../turntable/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = (result, resultIndex = -1) => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
    resultIndex,
  },
})

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

export const setChoices =
  (choices, resetAngle = true) =>
  async (dispatch, getState) => {
    if (resetAngle) {
      const state = getState()
      const startAngle = state.settings.spinBehavior.randomStart
        ? 2 * Math.PI * Math.random()
        : 0
      dispatch(setStartRotate(startAngle))
    }
    dispatch(setChoicesAction(choices))
  }

export const setChoicesAction = (choices, nullTurntable = true) => ({
  type: SET_CHOICES,
  payload: {
    choices,
    nullTurntable,
  },
})

export const setInputsStore = inputs => {
  return {
    type: SET_INPUTS,
    payload: {
      inputs,
    },
  }
}

export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => {
  return {
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  }
}

export const setUsername = username => ({
  type: SET_USERNAME,
  payload: {
    username,
  },
})

export const setPostPhoto = postPhoto => ({
  type: SET_POST_PHOTO,
  payload: {
    postPhoto,
  },
})

export const setResultReverse = status => {
  return {
    type: SET_RESULT_REVERSE,
    payload: {
      status,
    },
  }
}

export const changeKeyText = () => ({
  type: CHANGE_KEY_TEXT,
})

export const setNumWinners = num => ({
  type: SET_NUM_WINNERS,
  payload: {
    num,
  },
})

export const setAccessToken = token => {
  return {
    type: SET_ACCESS_TOKEN,
    payload: {
      token,
    },
  }
}

// export const setAutoSpin = status => {
//   return {
//     type: SET_AUTO_SPIN,
//     payload: {
//       status,
//     },
//   }
// }

export const setPostCode = postCode => ({
  type: SET_POST_CODE,
  payload: {
    postCode,
  },
})

export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode,
  },
})

export const openWinnerModal = (
  show,
  defaultPlace = 0,
  winnersList = [],
  winnersCount = 0,
) => {
  return {
    type: OPEN_WINNER_MODAL,
    payload: {
      show,
      defaultPlace,
      winnersList,
      winnersCount,
    },
  }
}
