import { createReducer } from '../reducerUtil'
import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_HISTORY,
  SET_TITLE_OBJ,
  INSTANT_INIT,
  SET_INPUTS,
  SET_USERNAME,
  SET_POST_PHOTO,
  SET_RESULT_REVERSE,
  CHANGE_KEY_TEXT,
  SET_NUM_WINNERS,
  SET_ACCESS_TOKEN,
  // SET_AUTO_SPIN,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
  SET_POST_CODE,
  SET_MODE,
  OPEN_WINNER_MODAL,
} from './constants'
import cuid from 'cuid'

const initialState = {
  resultSelected: '',
  resultIndex: -1,
  history: [],
  choices: [],
  title: {
    active: false,
    text: '',
    description: '',
    popup: '',
  },
  turnTable: null,
  turnTableSize: 300,
  inputs: [],
  username: null,
  postPhoto: null,
  resultReverse: true,
  inputKeyText: cuid(),
  numWinners: 1,
  accessToken: null,
  postCode: null,
  mode: 0,
  // autoSpin: true,
  winnerModal: {
    show: false,
    defaultPlace: 0,
    winnersList: [],
    winnersCount: 0,
  },
}

export const setResultSelected = (state, payload) => {
  return {
    ...state,
    resultSelected: payload.result,
    resultIndex: payload.resultIndex,
  }
}

export const setChoices = (state, payload) => {
  return {
    ...state,
    choices: payload.choices,
    turnTable: payload.nullTurntable ? null : state.turnTable,
  }
}

export const setHistory = (state, payload) => {
  return {
    ...state,
    history: payload.history,
  }
}

export const showTurntable = (state, payload) => {
  return {
    ...state,
    turnTable: payload.turntable,
  }
}

export const setTurntableSize = (state, payload) => {
  return {
    ...state,
    turnTable: state.turnTableSize === payload.size ? state.turnTable : null,
    turnTableSize: payload.size,
  }
}

export const setTitleObj = (state, payload) => {
  return {
    ...state,
    title: {
      ...state.title,
      ...payload.obj,
    },
  }
}

export const instantInit = (state, payload) => {
  if (!payload.previousState) {
    return {
      ...state,
    }
  } else {
    return {
      ...payload.previousState,
    }
  }
}

export const setInputs = (state, payload) => {
  return {
    ...state,
    inputs: payload.inputs,
  }
}

export const setUsername = (state, payload) => {
  return {
    ...state,
    username: payload.username,
  }
}

export const setPostPhoto = (state, payload) => {
  return {
    ...state,
    postPhoto: payload.postPhoto,
  }
}

export const setResultReverse = (state, payload) => {
  return {
    ...state,
    resultReverse: payload.status,
  }
}

export const changeKeyText = (state, payload) => {
  return {
    ...state,
    inputKeyText: cuid(),
  }
}

export const setNumWinners = (state, payload) => {
  return {
    ...state,
    numWinners: payload.num,
  }
}

export const setAccessToken = (state, payload) => {
  return {
    ...state,
    accessToken: payload.token,
  }
}

// export const setAutoSpin = (state, payload) => {
//   return {
//     ...state,
//     autoSpin: payload.status,
//   }
// }

export const resetInitialState = (state, payload) => {
  const { turnTableSize, accessToken, username, ...rest } = initialState
  return {
    ...state,
    ...rest,
  }
}

export const replaceState = (state, payload) => {
  let accessToken = state.accessToken
  let username = state.username
  if (payload.data.postPhoto) {
    // post selected
    if (state.username !== payload.data.username) {
      // different account, then logout
      username = payload.data.username
      accessToken = null
    }
  }
  return {
    ...state,
    ...payload.data,
    accessToken: accessToken,
    username: username,
  }
}

export const setPostCode = (state, payload) => {
  return {
    ...state,
    postCode: payload.postCode,
  }
}

export const setMode = (state, payload) => {
  return {
    ...state,
    mode: payload.mode,
  }
}

export const openWinnerModal = (state, payload) => {
  return {
    ...state,
    winnerModal: payload,
  }
}

export default createReducer(initialState, {
  [SHOW_TURNTABLE]: showTurntable,
  [SET_RESULT_SELECTED]: setResultSelected,
  [SET_CHOICES]: setChoices,
  [SET_HISTORY]: setHistory,
  [SET_TURNTABLE_SIZE]: setTurntableSize,
  [SET_TITLE_OBJ]: setTitleObj,
  [INSTANT_INIT]: instantInit,
  [SET_INPUTS]: setInputs,
  [SET_USERNAME]: setUsername,
  [SET_POST_PHOTO]: setPostPhoto,
  [SET_RESULT_REVERSE]: setResultReverse,
  [CHANGE_KEY_TEXT]: changeKeyText,
  [SET_NUM_WINNERS]: setNumWinners,
  [SET_ACCESS_TOKEN]: setAccessToken,
  // [SET_AUTO_SPIN]: setAutoSpin,
  [RESET_INITIAL_STATE]: resetInitialState,
  [REPLACE_STATE]: replaceState,
  [SET_POST_CODE]: setPostCode,
  [SET_MODE]: setMode,
  [OPEN_WINNER_MODAL]: openWinnerModal,
})
