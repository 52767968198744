import Colors from './colors.js'
import { size } from './device'
import { css } from 'styled-components'

const theme = {
  global: {
    // active: {
    //   background: {
    //     color: Colors.primary,
    //     opacity: 'medium',
    //   },
    //   color: 'white',
    // },
    colors: {
      ...Colors,
      brand: Colors.primary01,
      icon: Colors.greyIcon,
      // text: {
      //   dark: '#fff',
      //   light: '#222222',
      // },
    },
    focus: { border: { color: 'transparent' } },

    breakpoints: {
      ...size,
    },
    input: {
      font: {
        weight: 500,
        height: '24px',
      },
    },
    elevation: {
      light: {
        none: 'none',
        xsmall: '0px 1px 2px var(--dropShadowColor)',
        small: '0px 2px 4px var(--dropShadowColor)',
        medium: '0px 4px 8px var(--dropShadowColor)',
        large: '0px 8px 16px var(--dropShadowColor)',
        xlarge: '0px 12px 24px var(--dropShadowColor)',
      },
      dark: {
        none: 'none',
        xsmall: '0px 2px 2px var(--dropShadowColor)',
        small: '0px 4px 4px var(--dropShadowColor)',
        medium: '0px 6px 8px var(--dropShadowColor)',
        large: '0px 8px 16px var(--dropShadowColor)',
        xlarge: '0px 12px 24px var(--dropShadowColor)',
      },
    },
    hover: {
      color: Colors.text,
    },
    active: {
      color: Colors.text,
    },
    drop: {
      zIndex: 10001,
    },
    control: {
      disabled: {
        opacity: '0.5 !important',
      },
    },
  },

  icon: {
    size: {
      xsmall: '16px',
      small: '18px',
      twenty: '20px',
      medium: '24px',
      large: '30px',
    },
  },

  button: {
    // padding: {
    //   vertical: '.8rem',
    //   horizontal: '3rem',
    // },
    border: {
      color: Colors.transparent,
      radius: '3px',
      width: '0px',
    },
    // color: Colors.text,
    default: {
      color: Colors.text,
      padding: {
        vertical: '0.8rem',
        horizontal: '0.8rem',
      },
      extend: css`
        line-height: 0;
      `,
    },
    primary: {
      color: Colors.white,
      background: {
        color: Colors.primary,
      },
      padding: {
        vertical: '.8rem',
        horizontal: '3rem',
      },
      // extend: css`
      //   line-height: 24px;
      // `,
    },
    secondary: {
      color: Colors.text,
      padding: {
        vertical: '0.8rem',
        horizontal: '1.5rem',
      },
      extend: css`
        font-weight: 500 !important;
        border: ${props =>
          !props.border
            ? `0px solid ${Colors.transparent}`
            : `1px solid ${Colors.border}`};
      `,
    },
    active: {
      background: {
        color: Colors.primary,
      },
      color: Colors.white,
    },
    disabled: {
      default: {
        color: `${Colors.text} !important`,
      },
      primary: {
        color: `${Colors.white} !important`,
      },
      secondary: {
        color: `${Colors.text} !important`,
      },
    },
    hover: {
      primary: {
        extend: props => {
          return css`
            box-shadow: 0px 0px 0px 2px
              ${props.theme.global.colors[props.colorValue || 'secondary']};
          `
        },
      },
      secondary: {
        extend: css`
          background: ${Colors.hoverBg};
          /* color: ${Colors.white};
          fill: ${Colors.white};
          stroke: ${Colors.white}; */
        `,
      },
    },
    // secondary: {
    //   color: Colors.white,
    //   background: {
    //     color: Colors.secondary,
    //   },
    // },
    // secondary: {
    //   background: {
    //     color: Colors.secondary,
    //   },
    //   color: {
    //     dark: Colors.white,
    //     light: Colors.secondary,
    //   },
    // },
    size: {
      small: {
        border: {
          radius: '3px',
        },
      },
      medium: {
        border: {
          radius: '3px',
        },
      },
    },
    extend: css`
      /* line-height: 1; */
      font-weight: 700;
      &:focus-visible {
        background: ${Colors.hoverBg};
      }
    `,
  },
  heading: {
    weight: 600,
    // extend: {
    //   color: Colors.paragraph,
    // },
    color: 'heading',
    level: {
      1: {
        medium: {
          size: '3.3rem',
          height: '4rem',
        },
      },
      2: {
        medium: {
          size: '2.9rem',
        },
      },
      3: {
        medium: {
          size: '2.6rem',
        },
      },
      4: {
        medium: {
          size: '2.3rem',
        },
      },
    },
  },
  textInput: {
    extend: {
      padding: '.8rem',
    },
    // placeholder: {
    //   extend: {
    //     color: 'blue',
    //   },
    // },
  },
  checkBox: {
    gap: '.5rem',
    size: '3.5rem',
    color: Colors.primary,
    border: {
      color: Colors.inactiveBorder,
    },
    hover: {
      border: {
        color: Colors.primary,
      },
    },

    extend: css`
      &:has(:focus-visible) > div {
        outline: 3px solid -webkit-focus-ring-color;
        border-radius: 4px;
      }
    `,
  },
  //   textArea: {
  //     extend: {
  //       border: `5px solid ${props => props.theme.global.colors.primary}`,
  //     },
  //   },

  anchor: {
    fontWeight: 700,
    color: 'secondary',
  },
  layer: {
    overlay: {
      background: Colors.backdrop,
    },
  },

  text: {
    medium: {
      size: '1.8rem',
      lineHeight: '2.4rem',
    },
  },

  paragraph: {
    medium: {
      size: '1.8rem',
      // height: "24px",
      maxWidth: '73rem',
    },
    extend: {
      lineHeight: '2.8rem',
      marginBottom: '2rem',
    },
  },
  accordion: {
    border: {
      color: 'transparent',
    },
    panel: {
      border: {
        style: 'hidden',
      },
    },
    icons: {
      color: 'text',
    },
  },
  radioButton: {
    color: Colors.primary,
    hover: {
      border: {
        color: Colors.primary,
      },
    },
    check: {
      color: Colors.primary,
    },
    border: {
      color: 'inactiveBorder',
    },
    gap: '.5rem',
    size: '2rem',
    container: {
      extend: {
        lineHeight: '24px',
      },
    },
    extend: css`
      &:has(:focus-visible) > div {
        outline: 3px solid -webkit-focus-ring-color;
        border-radius: 50%;
      }
    `,
  },
  select: {
    // background: Colors.secondaryBg,
    // icons: {
    //   color: Colors.iconDarkLight,
    // },
    options: {
      text: {
        weight: 500,
      },
    },
    control: {
      extend: {
        border: 'none',
        borderRadius: 'none',
      },
    },
    icons: {
      margin: {
        horizontal: '.8rem',
      },
      color: 'iconDarkLight',
    },
    container: {
      extend: css`
        background: ${Colors.baseBackground};
        & > div::-webkit-scrollbar {
          width: 5px !important;
        }
        & > div::-webkit-scrollbar-track {
          background: transparent !important;
        }
        & > div::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: ${Colors.scrollThumb} !important;
        }
      `,
    },
    control: {
      extend: css`
        border: none;
        background: ${Colors.secondaryBg};
        &:focus-visible {
          background: ${Colors.hoverBg};
        }
      `,
    },
  },
  tip: {
    content: {
      margin: 'xsmall',
      pad: { horizontal: '1rem', vertical: '.7rem' },
      background: 'tooltipBg',
      round: { size: 'medium' },
      elevation: 'none',
    },
    drop: {
      elevation: 'none',
    },
  },
  rangeInput: {
    thumb: {
      color: Colors.primary,
    },
    track: {
      color: '#bbb', //can't put dynamic color
    },
  },
  // },
  // menu: {
  //   extend: css`
  //     padding: 1.2rem;
  //   `,
  // },
  // tabs: {
  //   gap: "0",
  //   background:"#acc",
  //   extend: {
  //     paddingLeft: "3rem",
  //     paddingRight: "3rem"

  //   }
  // },
  // tab: {
  //   color: Colors.inactive,
  //   active: {
  //     color: Colors.text,
  //     extend: {
  //       fontWeight: 700,
  //     }

  //   },
  //   margin: "0rem",
  //   extend: {
  //     fontWeight: 400,
  //   }
  // }
  // menu: {
  //   extend: {
  //     width: "20rem"
  //   },
  // },
  calendar: {
    day: {
      extend: ({ isSelected, inRange }) => `
        ${
          isSelected
            ? `
              background-color: #F7B71D;
              color: var(--invertTextStrong);
            `
            : ''
        }
        ${
          inRange
            ? `
              background-color: rgb(247, 183, 29, 0.2);
            `
            : ''
        }
      `,
    },
  },
}

export default theme
