export default {
  primary: 'var(--primary)',
  primary01: '#2b580c',
  primary02: '#afa939',
  // #8fb14e
  secondary: '#f7b71d',
  accent: '#fdef96',

  reject: '#D0021B',
  white: '#ffffff',
  black: '#000',
  dark: 'var(--border)',
  greyBg: '#4a4a4a',
  hoverBg: 'rgba(215, 215, 215, 0.4)',

  // text: '#222222',
  // scrollbar: "#ababab",

  // disabled: '#aaa',

  // placeholder: '#4A4A4A',
  // backdrop: '#000',
  // line: '#3D3D3D',
  // paragraph: '#222222',

  coffee: '#FF813F',

  // focus: 'rgba(0, 0, 0, 0.33)',

  // toolBg: "#B1BDA8", //second version layout started here

  // scrollbar: '#bbb',

  // inputBorder: 'rgba(0,0,0,0.15)',

  // boardBg: '#3D3D3D',

  // headerAdBg: '#ccc',

  // footerBg: '#ccc',

  // inactive: 'rgba(34, 34, 34, 0.54)',

  wheelPlaceholder: 'var(--light-7)',

  // whiteBgInputPlaceholder: 'var(--placeholder)',

  baseBackground: 'var(--baseBackground)',
  text: 'var(--text)',
  textStrong: 'var(--textStrong)',
  invertText: 'var(--invertText)',
  invertTextStrong: 'var(--invertTextStrong)',
  heading: 'var(--heading)',
  iconDark: 'var(--iconDark)',
  darkBgScrollThumb: 'var(--darkBgScrollThumb)',
  darkBgScrollTrack: 'var(--darkBgScrollTrack)',
  border: 'var(--border)',
  // borderInvert: 'var(--borderInvert)',
  toolTranslucentBg: 'var(--toolTranslucentBg)',
  inactiveBorder: 'var(--inactiveBorder)',
  primaryBg: 'var(--primaryBg)',
  secondaryBg: 'var(--secondaryBg)',
  placeholder: 'var(--placeholder)',
  tooltipBg: 'var(--tooltipBg)',
  scrollTrack: 'var(--scrollTrack)',
  scrollThumb: 'var(--scrollThumb)',
  iconDarkLight: 'var(--iconDarkLight)',
  inactiveText: 'var(--greyOrInactive)',
  greyIcon: 'var(--greyOrInactive)',
  backdrop: 'var(--backdrop)',
  disabled: 'var(--disabled)',
  inactive: 'var(--disabled)',
  resultBorder: 'var(--resultBorder)',
  whiteText: 'var(--whiteText)',
  blackText: 'var(--blackText)',
  sectionBg: 'var(--sectionBg)',
  // blackWhiteBorder: 'var(--blackWhiteBorder)',
  boardBg: 'var(--boardBg)',

  actionButtonBg: 'var(--actionButtonBg)',
  editingBg: 'var(--editingBg)',
  inactiveBg: 'var(--inactiveBg)',
  maybeBox: 'var(--maybeBox)',
}

// export default {
//   primary: '#2b580c',
//   primary02: '#afa939',

//   secondary: '#f7b71d',
//   accent: '#fdef96',

//   reject: '#D0021B',
// white: '#fff',
// black: '#000',
// 'light-1': '#F2F2F2',
// 'light-2': '#e3e3e3',
// 'light-3': '#D3D3D3',
// 'light-4': '#CCC',
// 'light-5': '#C3C3C3',
// 'light-6': '#bbb',
// 'light-7': '#aaa',
// 'dark-1': '#1e2126',
// 'dark-2': '#222',
// 'dark-3': '#3D3D3D',
// 'dark-4': '#4A4A4A',
// 'dark-5': '',

//   darkBgScrollThumb: 'light-2',
//   darkBgScrollTrack: 'dark-2',

//   border: {
//     dark: 'light-3',
//     light: 'dark-3',
//   },

//   // text: '#222222',
//   // scrollbar: "#ababab",

//   disabled: '#aaa',

//   placeholder: '#4A4A4A',
//   backdrop: '#000',
//   line: '#3D3D3D',
//   paragraph: '#222222',

//   coffee: '#FF813F',

//   focus: 'rgba(0, 0, 0, 0.33)',

//   // toolBg: "#B1BDA8", //second version layout started here

//   mainInput: '#D3D3D3',
//   inputList: '#F2F2F2',

//   scrollbar: '#bbb',

//   inputBorder: 'rgba(0,0,0,0.15)',

//   boardBg: '#3D3D3D',

//   headerAdBg: '#ccc',

//   footerBg: '#ccc',

//   inactive: 'rgba(34, 34, 34, 0.54)',

//   wheelPlaceholder: '#e3e3e3',

//   whiteBgInputPlaceholder: '#c3c3c3',

//   baseBackground: {
//     dark: 'dark-1',
//     light: 'white',
//   },
//   text: {
//     dark: 'light-2',
//     light: 'dark-2',
//   },
//   heading: {
//     dark: 'light-1',
//     light: 'dark-1',
//   },
// }

// const darkColors = [
//   '#333333',
//   '#555555',
//   '#777777',
//   '#999999',
//   '#999999',
//   '#999999',
// ]
// const lightColors = [
//   '#F8F8F8',
//   '#F2F2F2',
//   '#EDEDED',
//   '#DADADA',
//   '#DADADA',
//   '#DADADA',
// ]
