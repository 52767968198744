import {
  SHOW_TURNTABLE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_MODE,
  CHANGE_KEY_TEXT,
  SET_TURNTABLE_SIZE,
  SET_HISTORY,
  SET_TITLE_OBJ,
  RESET_INITIAL_STATE,
  REPLACE_STATE,
  SET_SHOW_MODE,
  SET_INPUT_SELECT_MODE,
  SET_INPUT_SELECTED,
  SET_COLOR_SCHEME_SELECTED,
  SET_INPUT_METHOD,
  UPDATE_COLOR_WHEEL_STATE,
  UPDATE_ALL_COLOR_WHEEL_STATES,
  UPDATE_PALETTE_WHEEL_STATE,
} from './constants'

import { setStartRotate } from '../turntable/actions'
import { setCurrentFileKey, setDataStatus } from '../common/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = (result, resultIndex = -1) => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
    resultIndex,
  },
})

export const setChoices =
  (choices, resetAngle = true) =>
  (dispatch, getState) => {
    if (resetAngle) {
      const state = getState()
      const startAngle = state.settings.spinBehavior.randomStart
        ? 2 * Math.PI * Math.random()
        : 0
      dispatch(setStartRotate(startAngle))
    }
    dispatch({
      type: SET_CHOICES,
      payload: {
        choices,
      },
    })
    dispatch(setDataStatus('Modified'))
  }

export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode,
  },
})

export const changeKeyText = () => ({
  type: CHANGE_KEY_TEXT,
})

export const setLoadSaveData =
  (id, choices = null, title = null, keyText = false) =>
  (dispatch, getState) => {
    dispatch(setCurrentFileKey(id))
    if (choices) dispatch(setChoices(choices))
    if (title) dispatch(setTitleObj(title))
    dispatch(setDataStatus('Unchanged'))
    if (keyText) dispatch(changeKeyText())
  }

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

export const itemWheelChanged =
  (newValue, index) => async (dispatch, getState) => {
    const choices = getState().rcw.choices || []
    let newChoices = choices.slice(0)
    newChoices[index].name = newValue
    dispatch(setChoices(newChoices, false))
  }

export const itemToggleChanged =
  (status, index) => async (dispatch, getState) => {
    const choices = getState().rcw.choices || []
    let newChoices = choices.slice(0)
    newChoices[index].filtered = !status
    dispatch(setChoices(newChoices))
  }

export const itemCloseClicked = id => async (dispatch, getState) => {
  const choices = getState().rcw.choices || []
  let array = choices.slice(0)
  array.splice(id, 1)
  if (array.length === 0) {
    dispatch(setMode(0))
  }
  dispatch(setChoices(array))
  dispatch(changeKeyText())
}

export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => dispatch => {
  dispatch({
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const resetInitialState = () => {
  return {
    type: RESET_INITIAL_STATE,
  }
}

export const replaceState = data => {
  return {
    type: REPLACE_STATE,
    payload: {
      data,
    },
  }
}

export const setShowMode = showMode => dispatch => {
  dispatch({
    type: SET_SHOW_MODE,
    payload: {
      showMode,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const setInputSelectMode = status => ({
  type: SET_INPUT_SELECT_MODE,
  payload: {
    status,
  },
})

export const setInputSelected = inputSelected => ({
  type: SET_INPUT_SELECTED,
  payload: {
    inputSelected,
  },
})

export const setColorSchemeSelected = colorSchemeSelected => ({
  type: SET_COLOR_SCHEME_SELECTED,
  payload: {
    colorSchemeSelected,
  },
})

export const setInputMethod = method => dispatch => {
  dispatch({
    type: SET_INPUT_METHOD,
    payload: {
      method,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const updateColorWheelState = (text, value) => dispatch => {
  dispatch({
    type: UPDATE_COLOR_WHEEL_STATE,
    payload: {
      text,
      value,
    },
  })
  dispatch(setDataStatus('Modified'))
}

export const updatePaletteWheelState = (text, value) => dispatch => {
  dispatch({
    type: UPDATE_PALETTE_WHEEL_STATE,
    payload: {
      text,
      value,
    },
  })
}

export const updateAllColorWheelStates = colorWheel => dispatch => {
  dispatch({
    type: UPDATE_ALL_COLOR_WHEEL_STATES,
    payload: colorWheel,
  })
  dispatch(setDataStatus('Modified'))
}
